import {mapActions, mapGetters} from "vuex";

export default {
  name: "delivery-payment",
  computed:{
    ...mapGetters({
      delivery: 'pages/page',
    })
  },
  watch:{
    '$route'(){
      this.fetchDeliveryPage('delivery-and-payment')
    }
  },
  created() {
    this.fetchDeliveryPage('delivery-and-payment')
  },
  methods: {
    ...mapActions({
      fetchDeliveryPage: 'pages/GET_PAGE',
    })
  }
}